!(function (s, i, t) {
    "use strict";
    s.fn.stickyNavbar = function (a) {
        var e = s.extend(
            {
                activeClass: "active",
                sectionSelector: "scrollto",
                animDuration: 350,
                startAt: 0,
                easing: "swing",
                animateCSS: !0,
                animateCSSRepeat: !1,
                cssAnimation: "fadeInDown",
                jqueryEffects: !1,
                jqueryAnim: "slideDown",
                selector: "a",
                mobile: !1,
                mobileWidth: 480,
                zindex: 9999,
                stickyModeClass: "sticky",
                unstickyModeClass: "unsticky",
            },
            a
            ),
            n = s("." + e.sectionSelector);
        return this.each(function () {

            var a = s(this),
                o = a.css("position"),
                l = a.css("zIndex"),
                r = a.outerHeight(!0),
                d = a.offset().top - r,
                c = "auto" === a.css("top") ? 0 : a.css("top"),
                m = "a" === e.selector ? a.find("li a") : a.find("li"),
                p = (a.find("li a[href*=#]"), s(i).scrollTop()),
                u = 0;


            var isScrolling = false;

            m.bind('touchmove', function() {
                isScrolling = true;
            });

            m.bind('touchstart', function() {
                isScrolling = false;
            });


            m.bind("click", "a", function (t) {
                if(isScrolling===true)
                {
                    t.preventDefault();
                }


            });

            function bbb() {
                alert("bla");

            }


            var f = function () {

                var p = s(i),
                    u = p.scrollTop(),
                    f = p.width(),
                    C = p.height();



                return !e.mobile && f < e.mobileWidth
                    ? void a.css("position", o)
                    : (
                        u >= d + e.startAt - r ? (a.removeClass(e.unstickyModeClass).addClass(" " + e.stickyModeClass),
                                a.css({ position: "fixed", zIndex: e.zindex }).stop(),
                                e.jqueryEffects
                                    ? (e.animateCSSRepeat || a.hide().stop()[e.jqueryAnim](e.animDuration, e.easing), a.hide().stop()[e.jqueryAnim](e.animDuration, e.easing))
                                    : e.animateCSS
                                    ? e.animateCSSRepeat
                                        ? a.addClass(e.cssAnimation + " animated").one("animationend webkitAnimationEnd MSAnimationEnd oAnimationEnd", function (s) {
                                            a.removeClass(e.cssAnimation + " animated");
                                        })
                                        : a.addClass(e.cssAnimation + " animated").one("animationend webkitAnimationEnd MSAnimationEnd oAnimationEnd")
                                    : a.stop())
                            : a
                                .css({ position: e.$selfPosition, zIndex: l })
                                .removeClass(e.stickyModeClass)
                                .addClass(" " + e.unstickyModeClass),
                    p.scrollTop() + C >= s(t).height() && (m.removeClass().last().addClass(), s(".sticky_menu li a").removeClass().last().addClass()),
                        void (
                            d - 2 >= u &&
                            (a.removeClass(e.cssAnimation + " animated"),
                                e.jqueryEffects
                                    ? (0 === u && (m.removeClass(), s(".sticky_menu li a").removeClass()),
                                        u >= d ? a.css({ position: "fixed", zIndex: e.zindex }).hide().stop()[e.jqueryAnim](e.animDuration, e.easing) : a.css({ position: o, zIndex: e.zindex }))
                                    : (0 === u && (m.removeClass(), s(".sticky_menu li a").removeClass()), a.css({ position: o, top: c }).stop().animate({ top: c }, e.animDuration, e.easing)))
                        ));
            };
            s(i).scroll(f), s(i).ready(f), s(i).resize(f);
        }



        );
    };
})(jQuery, window, document);




var positions = [];

jQuery('.scrollto').each(function() {
    positions[jQuery(this).attr('id')] = jQuery("#menu-"+ jQuery(this).attr('id')).offset().left;
});


jQuery('#test').on('click', function () {

    var menu_list = jQuery('#menu-list');
    var menu_element = jQuery('#menu-propertyPrices')

//    console.log(menu_element.width());

    var left_position = positions['propertyPrices'] - menu_list.width()/2 + menu_element.width()/2;

    console.log("element default left " + positions['propertyPrices']);
    console.log("half of menu " + menu_list.width()/2);
    console.log("half of element " + menu_element.width()/2);

    console.log("left position " + left_position);


    menu_list.scrollLeft(left_position);

    console.log(positions);



});


jQuery('.left-arrow').on('click', function () {
    var menu_list = jQuery('#menu-list');



    menu_list.animate({scrollLeft: menu_list.scrollLeft()-80}, 100);
});

jQuery('.right-arrow').on('click', function () {
    var menu_list = jQuery('#menu-list');

    menu_list.animate({scrollLeft: menu_list.scrollLeft()+80}, 100);

});





jQuery(window).scroll(function () {

    var position = jQuery(this).scrollTop();

    var active = 0;


    jQuery('.scrollto').each(function() {
        var section = jQuery(this);

        var section_top = section.offset().top;
        var section_bottom = section.offset().top + section.outerHeight(true);

        var id = section.attr('id');

        var menu_element = jQuery('#menu-'+ id)

        var menu_list = jQuery('#menu-list');

        menu_element.removeClass('active');


        if (position >= section_top && position < section_bottom) {
            menu_element.addClass('active');

            active=1;

            if(menu_element.offset().left<0 || menu_element.offset().left > menu_list.width() - menu_element.width())
            {
                var left_position = positions[id] - menu_list.width()/2 + menu_element.width()/2;
                menu_list.animate({scrollLeft: left_position}, 500);


            }
        }
    });

    if(active===0)
    {
        jQuery('#menu-overview').addClass('active');
    }

});
